"use client";

import { motion, Variants } from "framer-motion";
import React, { ReactNode, cloneElement, isValidElement, ReactElement } from "react";

interface AnimatedIconProps {
  icon: ReactNode;
  className?: string;
  variants?: Variants | null;
  iconProps?: Record<string, any>;
  iconSize?: string;
  transitionProps?: Record<string, any>;
}

/**
 * Composant AnimatedIcon - Anime une icône lorsqu'elle est survolée
 */
export function AnimatedIcon({
  icon,
  className = "mr-3 text-white",
  variants = null,
  iconProps = {},
  iconSize = "h-7 w-7",
  transitionProps = { duration: 1.0 }
}: AnimatedIconProps) {
  // Icône par défaut si aucune n'est fournie
  if (!icon) {
    console.warn("AnimatedIcon: No icon provided");
    return null;
  }

  // Variantes d'animation par défaut si aucune n'est fournie
  const defaultVariants: Variants = {
    initial: { rotate: 0 },
    hover: {
      rotate: [0, -10, 10, -5, 5, 0],
      transition: transitionProps
    }
  };

  return (
    <motion.div
      variants={variants || defaultVariants}
      className={className}
    >
      {isValidElement(icon) ? 
        <span className={iconSize}>
          {icon}
        </span>
        : icon
      }
    </motion.div>
  );
}

interface CardTitleWithIconProps {
  icon: ReactNode;
  title: string | ReactNode;
  className?: string;
  titleClassName?: string;
  iconProps?: Partial<AnimatedIconProps>;
}

/**
 * Composant CardTitleWithIcon - Encapsule un titre de carte avec une icône animée
 */
export function CardTitleWithIcon({
  icon,
  title,
  className = "flex items-center",
  titleClassName = "",
  iconProps = {}
}: CardTitleWithIconProps) {
  return (
    <div className={className}>
      <AnimatedIcon icon={icon} {...iconProps} />
      <div className={titleClassName}>{title}</div>
    </div>
  );
}

interface AnimationContainerProps {
  children: ReactNode;
  className?: string;
}

/**
 * Container pour l'animation au survol - À utiliser comme parent pour l'AnimatedIcon
 */
export function AnimationContainer({ children, className = "" }: AnimationContainerProps) {
  return (
    <motion.div
      initial="initial"
      whileHover="hover"
      className={className}
    >
      {children}
    </motion.div>
  );
} 