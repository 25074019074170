import React from "react";
import { motion } from "framer-motion";

import CourseThumb from "../Shared/CourseThumb";
// import DealThumb from "../Shared/DealThumb";

import AdCard from "../Shared/AdSense/AdCard";

// Animation pour les cartes de cours
const courseVariants = {
	hidden: { opacity: 0, scale: 0.95 },
	visible: {
		opacity: 1,
		scale: 1,
		transition: {
			type: "spring",
			duration: 0.5,
			bounce: 0.2
		}
	}
};

// Animation pour le container
const containerVariants = {
	hidden: { opacity: 0 },
	visible: {
		opacity: 1,
		transition: {
			staggerChildren: 0.1,
			delayChildren: 0.2
		}
	}
};

function Course({ course, isDarkTheme }) {
	return (
		<motion.div
			initial="hidden"
			animate="visible"
			variants={containerVariants}
			style={{
				display: "grid",
				justifyContent: "center",
				justifyItems: "center",
				alignItems: "start",
				gridTemplateColumns: "repeat(auto-fill, minmax(307px, 1fr))",
				columnGap: "20px",
				rowGap: "40px",
				width: "100%",
				maxWidth: "100%"
			}}
		>
			{course && course.map((element, index) => {
				if (element.detail[0]) {
					const course = {
						udemyId: element.udemyId,
						createdAt: element.createdAt,
						updatedAt: element.updatedAt,
						isFree: element.isFree,
						title: element.detail[0].title,
						image: element.detail[0].image_240,
						cleanUrl: element.cleanUrl,
						length: element.detail[0].lenghtTxt,
						rating: element.detail[0].rating,
						isPaid: element.detail[0].isPaid,
						price: element.detail[0].price / 100,
						updated: element.detail[0].updated,
						subscribers: element.detail[0].subscribers,
						locale: element.detail[0].locale.locale,
						instructorAvatar: element.instructor[0]?.image,
						instructorName: element.instructor[0]?.name,
						instructorCourses: element.instructor[0]?.course,
						instructorUrl: element.instructor[0]?.url,
					};
					return (
						<React.Fragment key={course.udemyId}>
							<motion.div
								variants={courseVariants}
								whileHover={{ 
									scale: 1.03,
									transition: {
										type: "spring",
										duration: 0.3,
										bounce: 0.3
									}
								}}
								className="w-auto"
							>
								<CourseThumb course={course} isDarkTheme={isDarkTheme} />
							</motion.div>
							{index % 5 === 0 && process.env.AD_CARD === "true" && <AdCard isDarkTheme={isDarkTheme} />}
						</React.Fragment>
					);
				}
				return null;
			})}
		</motion.div>
	);
}

export default Course;
