import React from "react";
import CourseThumb from "../Shared/CourseThumb";
import { motion } from "framer-motion";
import AdCard from "../Shared/AdSense/AdCard";

// Animation pour les cartes de cours
const courseVariants = {
	hidden: { opacity: 0, scale: 0.95 },
	visible: {
		opacity: 1,
		scale: 1,
		transition: {
			type: "spring",
			duration: 0.5,
			bounce: 0.2
		}
	}
};

// Animation pour le container
const containerVariants = {
	hidden: { opacity: 0 },
	visible: {
		opacity: 1,
		transition: {
			staggerChildren: 0.1,
			delayChildren: 0.2
		}
	}
};

function Coupon({ coupons, isDarkTheme }) {
	return (
		<motion.div
			initial="hidden"
			animate="visible"
			variants={containerVariants}
			style={{
				display: "grid",
				justifyContent: "center",
				justifyItems: "center",
				alignItems: "start",
				gridTemplateColumns: "repeat(auto-fill, minmax(307px, 1fr))",
				columnGap: "20px",
				rowGap: "40px",
				width: "100%",
				maxWidth: "100%"
			}}
		>
			{coupons && coupons.map((coupon, index) => {
				if (coupon.course.detail[0]) {
					const course = {
						udemyId: coupon.course.udemyId,
						title: coupon.course.detail[0].title,
						image: coupon.course.detail[0].image_240,
						cleanUrl: coupon.course.cleanUrl,
						length: coupon.course.detail[0].lenghtTxt,
						rating: coupon.course.detail[0].rating,
						isPaid: coupon.course.detail[0].isPaid,
						updated: coupon.course.detail[0].updated,
						subscribers: coupon.course.detail[0].subscribers,
						price: coupon.course.detail[0].price / 100,
						code: coupon.code,
						codeIsValid: coupon.isValid,
						couponCreatedAt: coupon.createdAt,
						discountValue: coupon.discountValue,
						discountPrice: coupon.discountPrice,
						maxUses: coupon.maxUses,
						remainingUses: coupon.remainingUses,
						endTime: coupon.endTime,
						locale: coupon.course.detail[0].locale.locale,
						instructorAvatar: coupon.course.instructor[0].image,
						instructorName: coupon.course.instructor[0].name,
						instructorCourses: coupon.course.instructor[0].course,
						instructorUrl: coupon.course.instructor[0].url,
					};

					return (
						<React.Fragment key={course.udemyId}>
							<motion.div
								variants={courseVariants}
								whileHover={{ 
									scale: 1.03,
									transition: {
										type: "spring",
										duration: 0.3,
										bounce: 0.3
									}
								}}
								className="w-auto"
							>
								<CourseThumb course={course} isDarkTheme={isDarkTheme} />
							</motion.div>
							{index % 5 === 0 && process.env.AD_CARD === "true" && <AdCard isDarkTheme={isDarkTheme} />}
						</React.Fragment>
					);
				}
				return null;
			})}
		</motion.div>
	);
}

export default Coupon;
