"use client"
import { useState, } from "react";
import { motion } from "framer-motion";
import Coupon from "./Coupon";
import Course from "./Course";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { CountBadge } from "../Shared/CountBadge";
import { cn } from "@/helpers/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "lucide-react";
import { AnimatedIcon, AnimationContainer, CardTitleWithIcon } from "../Shared/AnimatedIcon";

// Animation variants
const containerVariants = {
	hidden: { opacity: 0 },
	visible: {
		opacity: 1,
		transition: {
			staggerChildren: 0.1
		}
	}
};

const itemVariants = {
	hidden: { opacity: 0, y: 20 },
	visible: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.5
		}
	}
};

// Animation pour les cartes de cours
const courseVariants = {
	hidden: { opacity: 0, scale: 0.9 },
	visible: {
		opacity: 1,
		scale: 1,
		transition: {
			duration: 0.4
		}
	}
};

export function DailyView({ coupons, free, discount, expired }) {
	const [currentTab, setCurrentTab] = useState("100%OFF");
	
	// État pour gérer les éléments visibles par type
	const [visibleCoupons, setVisibleCoupons] = useState(coupons.slice(0, 10));
	const [visibleFree, setVisibleFree] = useState(free.slice(0, 10));
	const [visibleDiscount, setVisibleDiscount] = useState(discount.slice(0, 10));
	const [visibleExpired, setVisibleExpired] = useState(expired.slice(0, 10));
	
	// État pour le chargement
	const [loading, setLoading] = useState(false);
	
	// Éléments par page et tracking
	const ITEMS_PER_PAGE = 10;
	const [visibleItems, setVisibleItems] = useState({
		"100%OFF": 10,
		"FREE": 10,
		"DISCOUNT": 10,
		"EXPIRED": 10
	});
	
	const allTabs = [
		{
			name: "100%OFF",
			count: coupons.length,
			textColor: "text-yellow-500",
			bgColor: "bg-yellow-500",
			borderColor: "border-yellow-500",
			glowColor: "shadow-yellow-400/20",
			activeClasses: "data-[state=active]:border-yellow-500 data-[state=active]:text-yellow-500",
		},
		{
			name: "FREE",
			count: free.length,
			textColor: "text-green-500",
			bgColor: "bg-green-500",
			borderColor: "border-green-500",
			glowColor: "shadow-green-400/20",
			activeClasses: "data-[state=active]:border-green-500 data-[state=active]:text-green-500",
		},
		{
			name: "DISCOUNT",
			count: discount.length,
			textColor: "text-red-500",
			bgColor: "bg-red-500",
			borderColor: "border-red-500",
			glowColor: "shadow-red-400/20",
			activeClasses: "data-[state=active]:border-red-500 data-[state=active]:text-red-500",
		},
		{
			name: "EXPIRED",
			count: expired.length,
			textColor: "text-blue-500",
			bgColor: "bg-blue-500",
			borderColor: "border-blue-500", 
			glowColor: "shadow-blue-400/20",
			activeClasses: "data-[state=active]:border-blue-500 data-[state=active]:text-blue-500",
		},
	];

	const tabs = allTabs.filter((tab) => {
		return tab.count > 0;
	});

	// Fonction pour gérer le changement de tab
	const handleTabChange = (value) => {
		setCurrentTab(value);
	};
	
	// Fonction pour charger plus d'éléments
	const loadMoreItems = () => {
		if (loading) return;
		
		setLoading(true);
		
		// Utiliser un timeout pour simuler un chargement asynchrone
		setTimeout(() => {
			const currentCount = visibleItems[currentTab];
			const newCount = currentCount + ITEMS_PER_PAGE;
			
			// Mettre à jour le nombre d'éléments visibles pour l'onglet actuel
			setVisibleItems(prev => ({
				...prev,
				[currentTab]: newCount
			}));
			
			// Mettre à jour les éléments visibles en fonction de l'onglet actuel
			switch (currentTab) {
				case "100%OFF":
					setVisibleCoupons(coupons.slice(0, newCount));
					break;
				case "FREE":
					setVisibleFree(free.slice(0, newCount));
					break;
				case "DISCOUNT":
					setVisibleDiscount(discount.slice(0, newCount));
					break;
				case "EXPIRED":
					setVisibleExpired(expired.slice(0, newCount));
					break;
			}
			
			setLoading(false);
		}, 300);
	};
	
	// Calculer s'il y a plus d'éléments à charger pour chaque tab
	const hasMore = {
		"100%OFF": visibleCoupons.length < coupons.length,
		"FREE": visibleFree.length < free.length,
		"DISCOUNT": visibleDiscount.length < discount.length,
		"EXPIRED": visibleExpired.length < expired.length
	};

	return (
		<div className="min-h-screen md:pb-6 bg-gradient-to-r from-blue-600 to-blue-700 dark:bg-[#111927] dark:from-transparent dark:to-transparent">
			<main className="bg-gradient-to-r from-blue-600 to-blue-700 dark:bg-[#111927] dark:from-transparent dark:to-transparent pt-6 pb-12">
				<motion.div
					initial="hidden"
					animate="visible"
					variants={containerVariants}
					className="w-full max-w-[1700px] mx-auto"
				>
					<motion.div variants={itemVariants}>
						<Card className="mx-auto max-w-[1700px] shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden bg-transparent border-2 border-blue-500/50 dark:bg-transparent dark:border-blue-400/50">
							<AnimationContainer className="pb-2">
								<CardHeader className="pt-8 pb-6 text-center">
									<motion.div
										initial={{ y: -20, opacity: 0 }}
										animate={{ y: 0, opacity: 1 }}
										transition={{ duration: 0.5 }}
									>
										<div className="flex items-center justify-center">
											<CardTitle className="text-2xl font-medium text-white sm:text-3xl">
												<CardTitleWithIcon 
													icon={<Calendar />}
													title="Daily Freebies"
												/>
											</CardTitle>
											<CountBadge count={coupons.length + free.length + discount.length + expired.length} />
										</div>
										<CardDescription className="mt-2 text-lg text-white/80">
											Last 24 hours activity
										</CardDescription>
									</motion.div>
								</CardHeader>
							</AnimationContainer>
							<Separator className="mb-0 bg-blue-500/50 dark:bg-blue-400/50" />
							<CardContent className="p-0">
								<Tabs 
									value={currentTab}
									onValueChange={handleTabChange}
									className="w-full " 
								>
									{/* Tabs pour mobile */}
									<motion.div 
										className="block p-4 md:hidden"
										variants={itemVariants}
									>
										<select
											id="tabs"
											name="tabs"
											className="block w-full py-2 pl-3 pr-10 text-base font-bold text-center text-white border-2 rounded-md shadow-md focus:outline-none sm:text-sm bg-blue-700/40 border-blue-500/50 focus:ring-blue-500 dark:bg-blue-400/10 dark:border-blue-400/50 dark:focus:ring-blue-400/50"
											value={currentTab}
											onChange={(event) => {
												handleTabChange(event.target.value);
											}}
										>
											{tabs.map((tab) => (
												<option key={tab.name} value={tab.name} className="text-center">
													{tab.name} ({tab.count})
												</option>
											))}
										</select>
									</motion.div>

									{/* TabsList pour desktop */}
									<motion.div 
										className="hidden md:block"
										variants={itemVariants}
									>
										<div className="p-4 pt-6 pb-0">
											<TabsList className="flex w-full h-12 p-1 border shadow-inner rounded-xl bg-blue-700/30 border-blue-500/30 dark:bg-blue-400/10 dark:border-blue-400/30">
												{tabs.map((tab) => {
													const Icon = tab.icon;
													return (
														<TabsTrigger
															key={tab.name}
															value={tab.name}
															className={cn(
																"flex-1 py-3 rounded-lg text-base font-medium transition-all cursor-pointer",
																"data-[state=active]:shadow-md",
																"text-white/80 data-[state=active]:text-white",
																"border border-transparent",
																"data-[state=active]:bg-blue-600/70 data-[state=active]:border-2 data-[state=active]:border-blue-300/80 hover:bg-blue-600/30 hover:border-blue-500/20 dark:data-[state=active]:bg-blue-400/20 dark:data-[state=active]:border-blue-400/50 dark:hover:bg-blue-400/10 dark:hover:border-blue-400/30",
																"data-[state=active]:scale-[1.03]",
																"data-[state=active]:" + tab.glowColor,
																"hover:text-white hover:scale-[1.02] hover:shadow-sm"
															)}
														>
															<div className="flex items-center justify-center gap-2">
																<span>{tab.name}</span>
																<span className="rounded-full px-2 py-0.5 text-sm font-bold bg-blue-600/40 dark:bg-blue-400/20 text-white">
																	{tab.count}
																</span>
															</div>
														</TabsTrigger>
													);
												})}
											</TabsList>
										</div>
									</motion.div>

									{/* Contenu des tabs */}
									<div className="p-4 py-6 pt-10">
										<TabsContent value="100%OFF" className="m-0">
											<Coupon coupons={visibleCoupons} />
										</TabsContent>
										<TabsContent value="FREE" className="m-0">
											<Course course={visibleFree} />
										</TabsContent>
										<TabsContent value="DISCOUNT" className="m-0">
											<Coupon coupons={visibleDiscount} />
										</TabsContent>
										<TabsContent value="EXPIRED" className="m-0">
											<Coupon coupons={visibleExpired} />
										</TabsContent>
									</div>
								</Tabs>
								
								{/* Bouton "Load more" */}
								{hasMore[currentTab] && (
									<div className="flex justify-center mt-8 mb-8">
										<Button
											variant="outline"
											onClick={loadMoreItems}
											disabled={loading}
											className="flex items-center gap-2 px-8 py-6 text-lg font-semibold text-white bg-blue-600 border-2 border-blue-300 hover:bg-blue-500 hover:border-blue-400 hover:text-white dark:bg-blue-400/10 dark:border-blue-400/50 dark:hover:bg-blue-400/30 dark:hover:border-blue-400/60 dark:hover:text-white"
										>
											{loading ? "Loading..." : "Load more"}
										</Button>
									</div>
								)}
							</CardContent>
						</Card>
					</motion.div>
				</motion.div>
			</main>
		</div>
	);
}